import { Modal } from "booking_app/types/modal";
import { modalSettings } from "booking_app/constants/modal-settings";

modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalIconCDNPath = "/vma/icons/icn-failed-red.svg";
modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalHeader = "Bummer!";
modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalBody = "wl.vma.cancellation_policy_error_message";
modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.resolveButtonText = "OK";
modalSettings[Modal.CANCEL_BOOKING].content.modalHeader = "Just checking...";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalIconCDNPath = "/vma/icons/emailsent-red.svg";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalHeader = "wl.booking_cancelled_header";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalBody = "wl.vma.confirmation_email_sent";
modalSettings[Modal.CANCEL_BOOKING_SUCCESS].content.resolveButtonText = "OK";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalIconCDNPath = "/vma/icons/icn-failed-red.svg";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalHeader = "wl.vma.flights.itinerary_unavailable";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.modalBody = "wl.vma.flights.reselect";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE].content.resolveButtonText = "Change flights";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalIconCDNPath = "/vma/icons/icn-failed-red.svg";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalHeader = "wl.vma.flights.itinerary_unavailable";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.modalBody = "wl.vma.flights.reselect";
modalSettings[Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE].content.resolveButtonText = "Change flights";
modalSettings[Modal.CANCEL_BOOKING_REQUEST].content.modalBody = "wl.vma.my_bookings.cancel_booking_request.are_you_sure";
modalSettings[Modal.CANCEL_BOOKING_REQUEST].content.resolveButtonText = "Yes, cancel";
modalSettings[Modal.CANCEL_BOOKING_REQUEST].content.rejectButtonText = "No, keep booking";
modalSettings[Modal.CANCEL_BOOKING_REQUEST_SUCCESS].content.modalIconCDNPath = "/vma/icons/emailsent-red.svg";
